#app_id {
    position: absolute;
    left: 50%;
    margin-left: -400px;

}

#timer {
    position: absolute;
    left: 15px;
    top: 4px;

}

.container {
    display: flex;
    padding-top: 4px;
}


.numeral {
    position: relative;
    width: 24px;
    /*display: inline-block;*/
}



.numeral:nth-child(3) {
    padding-left: 2px;
}


.imag {
    border: solid #28a134e6 1px;
    /*display: inline-block;*/
    position: absolute;
    top: 0;

    /*opacity: 0;*/
    visibility: hidden;
}

.nav {
    position: absolute;
    top: 0;
    padding-top: 10px;
    margin-left: 120px;
    z-index: 200;
}

#video {
    display: inline-block;
    margin-left: 40px;
}

.video {
    display: inline-block;
    text-align: center;

}

.bottom {
    background-color: #3498db;
    padding: 20px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 10px;
    width: 90%;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 30px;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #444;
}

.leaders {
    display: inline-block;
    width: 760px;
    margin-top: 30px
}

input {
    width: 160px;
    margin: 5px;
}

#pin {
    width: 60px;
    margin: 5px;
}

#controls {
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 50px;
    /* border: dotted red; */
    z-index: 1300;
    color: white;
}

.player-on-board {
    color: lightgreen;
}

.scratch {
    display: block;
    position: absolute;
    padding: 20px;
    padding-top: 30px;
    top: 740px;
    color: #bbb;
}

.scratch p {
    padding: 9px;
    width: 700px;
}

.scratch a {
    text-decoration: none;
    color: rgb(88, 88, 147)
}

.scratch a:hover {
    text-decoration: underline;
    color: rgb(131, 131, 244)
}

a {
    text-decoration: none;
    color: #ddf
}

a:hover {
    text-decoration: underline;
    color: #ddf
}

.bottom a {
    text-decoration: none;
    color: #ddf
}

.bottom a:hover {
    text-decoration: underline;
    color: #ddf
}

.footer {
    position: absolute;
    top: 480px;
    left: 40px;
    padding: 10px;
    padding-top: 100px;
    width: 700px;
    color: #bbeeff;
    background-color: #3498db;
    border-radius: 14px;
    font-size: 1rem;
}

.copyright {
    font-size: .8rem;
    color: #90BCF0;

}

.scale_icon {
    position: relative;
    padding-left: 6px;
    width: 1.6em;

}

.title {
    font-size: 24px;
    color: #09f;
}

.btn {
    display: inline-block;
    z-index: 100;
    background: #3498db;
    background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
    background-image: -moz-linear-gradient(top, #3498db, #2980b9);
    background-image: -ms-linear-gradient(top, #3498db, #2980b9);
    background-image: -o-linear-gradient(top, #3498db, #2980b9);
    background-image: linear-gradient(to bottom, #3498db, #2980b9);
    -webkit-border-radius: 12;
    -moz-border-radius: 12;
    border-radius: 12px;
    font-family: Arial;
    color: #ffffff;
    font-size: 12px;
    padding: 6px 12px 6px 12px;
    text-decoration: none;
}

.btn:hover {
    background: #3cb0fd;
    background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
    background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
    text-decoration: none;
}

.messenger {
    font-size: 18px;
    top: 26px;
    left: 50px;
    padding: 0px;
    position: absolute;
    width: 760px;
    display: block;
    color: #90BCF0;
    font-size: 1rem;
}

.feather {
    position: absolute;
    border-radius: 50px;
    display: inline-block;
    top: -80px;
}

.ankh {
    position: absolute;
    border-radius: 50px;
    display: inline-block;
    top: -20px;
}

.scale {

    position: absolute;
    left: 166px;
    right: 0px;
    top: 70px;

}

.cover {
    position: absolute;
    left: 191px;
    right: 0;
    top: 34px;

}

.scale_arm {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

}

.scale_left_plate {
    position: absolute;
    left: -140px;
    right: 0px;
    top: 190px;
}

.scale_right_plate {
    position: absolute;
    left: 320px;
    right: 0px;
    top: 190px;
}

.spinner {
    position: absolute;
    left: 191px;
    right: 0px;
    top: 38px;
}

.bg {
    height: 660px;
    width: 800px;
    /* position: absolute; */
    left: 0px;
    right: 55px;
    top: 0px;
    /* border: dotted green 6px; */
}

.coin {
    position: absolute;
    /*display: inline-block;*/
    border-radius: 70px;
}

.coins {
    position: absolute;
    top: 470px;
}

.label {
    border-radius: 0px;
    position: absolute;
    top: -20px;
    left: 38px;
    background-color: #fe0;
    width: 32px;
    padding: 5px;
    padding-bottom: 12px;
    z-index: -2;
}

#ankh-label {
    border-radius: 0px;
    position: absolute;
    top: 73px;
    left: 48px;
    background-color: #fe0;
    min-width: 32px;
    padding: 5px;
    padding-top: 22px;
    z-index: -2;
    transform: rotate(45deg);
}

#feather-label {
    border-radius: 0px;
    position: absolute;
    top: 150px;
    left: 6px;
    background-color: #fe0;
    min-width: 32px;
    padding: 5px;
    padding-top: 14px;
    z-index: -2;
    transform: rotate(45deg);
}